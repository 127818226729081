<template>
  <div
    :class="{
      'todo-list-warp': true,
      'four-style': +systemConfig.theme_type === 4,
    }"
    @scroll="scrollEvent"
  >
    <div
      class="list-item"
      v-for="(item, index) in list"
      :key="index"
      @click.stop="handleClick(item)"
    >
      <div class="info-warp">
        <div
          :class="{ 'before-border': true }"
          :style="{
            backgroundColor: stateList.find((s) => s.value === +item.status)
              ?.backgroundColor,
          }"
        ></div>
        <div class="info-text-warp">
          <div class="line-1">
            <div class="line-title">
              <div class="info-title">
                {{ item.object_name }}
                |
                {{ item.title }}
              </div>
            </div>
            <p
              class="status"
              :class="filterStatus(item.status).class"
              :style="{
                backgroundColor: stateList.find((s) => s.value === +item.status)
                  ?.color,
              }"
            >
              {{ stateList.find((s) => s.value === +item.status)?.label }}
            </p>
            <div :class="{ read: true, unread: item.read === 0 }">
              <div class="circle" v-if="item.read === 0"></div>
              {{ item.read ? '已读' : '未读' }}
            </div>
          </div>
          <div class="line-2">
            <p class="project-name">项目：{{ item.archi_name }}</p>
          </div>
          <div class="line-2">
            <p class="project-info">
              抄送人：
              <img
                class="avatar"
                :src="
                  item.user_avatar ||
                  'https://dwh_api.bimcc.net/uploads/202301/17/1673943165_C8E5tNmVgm.png'
                "
                alt=""
              />{{ item.user_name }}
            </p>
            <p class="time-status">
              {{ timeFormat(item.endDate, 'yyyy-MM-dd HH:mm') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <Empty
      v-if="list.length === 0"
      description="暂无数据"
      :image-size="100"
    ></Empty>
  </div>
</template>
<script>
import { format } from 'date-fns';
import { Empty } from 'element-ui';
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['systemConfig']),
  },
  components: {
    Empty,
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
      require: true,
    },
    refreshList: {
      type: Function,
      default() {
        return () => {};
      },
      require: true,
    },
  },
  data() {
    return {
      stateList: [
        {
          value: -1,
          label: '退回',
          color: '#FFECEC',
          backgroundColor: 'rgba(255, 198, 198, 1)',
        },
        {
          value: 0,
          label: '发起',
          color: '#DFF7E5',
          backgroundColor: 'rgba(125, 234, 164, 1)',
        },
        {
          value: 1,
          label: '审核中',
          color: '#E7F0FE',
          backgroundColor: 'rgba(165, 199, 254, 1)',
        },
        {
          value: 2,
          label: '完成',
          color: '#F0F1F4',
          backgroundColor: '#707786',
        },
        {
          value: 3,
          label: '审核中',
          color: '#E7F0FE',
          backgroundColor: 'rgba(165, 199, 254, 1)',
        },
        {
          value: 4,
          label: '审核中',
          color: '#E7F0FE',
          backgroundColor: 'rgba(165, 199, 254, 1)',
        },
        {
          value: 5,
          label: '完成',
          color: '#F0F1F4',
          backgroundColor: '#707786',
        },
        {
          value: 6,
          label: '拒绝',
          color: '#FFECEC',
          backgroundColor: 'rgba(255, 198, 198, 1)',
        },
        {
          value: 7,
          label: '作废',
          color: '#F0F1F4',
          backgroundColor: '#707786',
        },
      ],
    };
  },
  methods: {
    handleClick(item) {
      console.log(item);
      let data = {
        event: 'showDetailModal',
        form_uuid: item.form_id,
        flow_id: item.flow_id,
        data_id: item.id,
        module_name: item.module_name,
        object_name: item.object_name,
        archi_type: item.archi_type,
        tag: 'CCToMeTotal',
        ...(+item.read === 0 && {
          show_footer: true,
          is_read: true,
          msg_id: item.msg_id,
        }),
      };

      if (item.task_engine_id) {
        let {
          form_type,
          func_form,
          task_recover_id,
          task_engine_id,
          task_log_id,
          object_uuid,
        } = item;
        const taskConfig = {
          form_type,
          func_form,
          task_recover_id,
          task_engine_id,
          task_log_id,
          object_uuid,
        };
        data.taskConfig = taskConfig;
      }

      window.parent.postMessage(data, '*');
      return;
    },
    filterIcon(text) {
      if (!text || text.length === 0) {
        return {
          text: '-',
          class: '',
        };
      }
      const str = text[0];
      const classHash = {
        安: 's-icon',
        质: 'q-icon',
        进: 'p-icon',
      };
      return {
        text: str,
        class:
          classHash[str] ||
          classHash[
            Object.keys(classHash)[
              Math.floor(Math.random() * Object.keys(classHash).length)
            ]
          ],
      };
    },
    filterStatus(status) {
      switch (status + '') {
        case '-1': {
          return {
            label: '退回',
            class: 't-status',
          };
        }
        case '6': {
          return {
            label: '拒绝',
            class: 't-status',
          };
        }
        case '0': {
          return {
            label: '发起',
            class: 'f-status',
          };
        }
        case '1':
        case '3':
        case '4': {
          return {
            label: '审核中',
            class: 's-status',
          };
        }
        case '2':
        case '5': {
          return {
            label: '完成',
            class: 'w-status',
          };
        }
        case '7': {
          return {
            label: '作废',
            class: 'w-status',
          };
        }
        default: {
          return {
            label: '发起',
            class: 'f-status',
          };
        }
      }
    },
    // 时间格式化
    timeFormat(time) {
      const targetDate = new Date(time);
      return format(targetDate, 'yyyy-MM-dd HH:mm');
    },
    scrollEvent(e) {
      this.$emit('send-scroll-event', e);
    },
    refresh(e) {
      if (e.data.event === 'refresh-todo-list') {
        this.$emit('refreshList', true);
      }
    },
  },
  mounted() {
    window.addEventListener('message', this.refresh);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.refresh);
  },
};
</script>

<style lang="less" scoped>
.todo-list-warp {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .list-item {
    width: 100%;
    display: flex;
    padding: 12px 22px;
    box-sizing: border-box;
    align-items: flex-end;
    cursor: pointer;
    &:hover {
      background: #f5f5f5;
      border-radius: 3px;
    }
    .info-warp {
      flex-grow: 1;
      width: 100px;
      display: flex;
      position: relative;
      .before-border {
        position: absolute;
        width: 2px;
        height: 62px;
        left: -12px;
        top: 50%;
        border-radius: 2px;
        transform: translateY(-50%);
      }
      .q-icon {
        background: rgba(90, 204, 120, 0.2);
        color: #5acc78;
      }
      .s-icon {
        background: rgba(255, 170, 74, 0.2);
        color: #ffaa4a;
      }
      .p-icon {
        background: rgba(51, 187, 255, 0.2);
        color: #33bbff;
      }
      .other-icon {
        background: #f1f1f1;
      }
      .info-text-warp {
        flex-grow: 1;
        width: 100px;
        gap: 4px;
        display: flex;
        flex-direction: column;
        .line-1 {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          .line-title {
            // flex: 1;
            overflow: hidden;
            position: relative;
            height: 22px;
            margin-right: 8px;
          }
          .info-title {
            flex-grow: 1;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #292f3d;
            line-height: 22px;
            // display: inline-block;
            // position: absolute;
            left: 0px;
          }
          .status {
            flex-shrink: 0;
            margin-left: 8px;
            height: 22px;
            padding: 0 12px;
            box-sizing: border-box;
            border-radius: 6px;
            line-height: 22px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            white-space: normal;
          }
          .t-status {
            background: #ffecec;
            color: #e0452d;
          }
          .f-status {
            background: #dff7e5;
            color: #21a35d;
          }
          .s-status {
            color: #0088cc;
            background: #e7f0fe;
          }
          .w-status {
            color: #596058;
            background: #f0f1f4;
          }
          .read {
            color: #a4acbd;
            display: flex;
            justify-content: flex-end;
            flex: 1;
            flex-shrink: 0;
            flex-wrap: nowrap;
            min-width: 75px;
          }
          .unread {
            display: flex;
            align-items: center;
            gap: 4px;
            color: #707786;
            .circle {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: linear-gradient(
                  180deg,
                  rgba(255, 255, 255, 0.2) 0%,
                  rgba(255, 255, 255, 0) 100%
                ),
                var(--semantic-error-background-variant, #fc5558);
            }
          }
        }
        .line-2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 2px;
          height: 18px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6d768f;
          line-height: 18px;
          .project-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-grow: 1;
            width: 10px;
          }
          .time-status {
            flex-shrink: 0;
            white-space: nowrap;
            height: 18px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6d788f;
            line-height: 18px;
            margin-right: 5px;
          }
          .avatar {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            overflow: hidden;
          }
          .project-info {
            display: flex;
            align-items: center;
            gap: 6px;
          }
        }
      }
    }
    // .time-status{
    //     flex-shrink: 0;
    //     white-space: nowrap;
    //     height: 18px;
    //     font-size: 12px;
    //     font-family: PingFangSC-Regular, PingFang SC;
    //     font-weight: 400;
    //     color: #6D788F;
    //     line-height: 18px;
    //     margin-right: 5px;
    // }
  }
}
@keyframes todayScroll {
  0% {
    left: 0%;
  }

  100% {
    left: -35%;
  }
}
.four-style {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .list-item {
    border-radius: 12px;
    border: 1px solid #f9f6f4;
    background: var(--overall-surface-default, #fff);
    padding: var(--spacing-6, 12px) 14px;
    &:hover {
      border-radius: 12px;
      border: 1px solid #f9f6f4;
      background: linear-gradient(
        180deg,
        rgba(247, 171, 25, 0) 2.19%,
        rgba(242, 119, 19, 0.1) 52.39%,
        rgba(231, 0, 6, 0.1) 98.44%
      );
    }
    .info-warp {
      .info-icon {
        display: flex;
        width: var(--button-list-field-large, 40px);
        height: var(--button-list-field-large, 40px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: var(--radius-4, 8px);
        font-family: YouSheBiaoTiHei;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .info-text-warp {
      .line-1 {
        .line-title {
          .info-title {
            overflow: hidden;
            color: var(--text-on-surface-primary, #181b22);
            text-overflow: ellipsis;

            /* web/cn/helper/helper-03 */
            font-family: 'PingFang SC';
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
            letter-spacing: 0.4px;
          }
        }
      }
      .line-2 {
        .project-name {
          color: var(--text-on-surface-tertiary, #707786);

          /* web/cn/helper/helper-02 */
          font-family: 'PingFang SC';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 18px */
          letter-spacing: 0.4px;
        }
        .time-status {
          color: var(--text-on-surface-placeholder, #a4acbd);

          /* web/cn/helper/helper-02 */
          font-family: 'PingFang SC';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 18px */
          letter-spacing: 0.4px;
        }
      }
    }
  }
}
</style>
