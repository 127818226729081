var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'todo-list-warp': true,
    'four-style': +_vm.systemConfig.theme_type === 4,
  },on:{"scroll":_vm.scrollEvent}},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"list-item",on:{"click":function($event){$event.stopPropagation();return _vm.handleClick(item)}}},[_c('div',{staticClass:"info-warp"},[_c('div',{class:{ 'before-border': true },style:({
          backgroundColor: _vm.stateList.find((s) => s.value === +item.status)
            ?.backgroundColor,
        })}),_c('div',{staticClass:"info-text-warp"},[_c('div',{staticClass:"line-1"},[_c('div',{staticClass:"line-title"},[_c('div',{staticClass:"info-title"},[_vm._v(" "+_vm._s(item.object_name)+" | "+_vm._s(item.title)+" ")])]),_c('p',{staticClass:"status",class:_vm.filterStatus(item.status).class,style:({
              backgroundColor: _vm.stateList.find((s) => s.value === +item.status)
                ?.color,
            })},[_vm._v(" "+_vm._s(_vm.stateList.find((s) => s.value === +item.status)?.label)+" ")]),_c('div',{class:{ read: true, unread: item.read === 0 }},[(item.read === 0)?_c('div',{staticClass:"circle"}):_vm._e(),_vm._v(" "+_vm._s(item.read ? '已读' : '未读')+" ")])]),_c('div',{staticClass:"line-2"},[_c('p',{staticClass:"project-name"},[_vm._v("项目："+_vm._s(item.archi_name))])]),_c('div',{staticClass:"line-2"},[_c('p',{staticClass:"project-info"},[_vm._v(" 抄送人： "),_c('img',{staticClass:"avatar",attrs:{"src":item.user_avatar ||
                'https://dwh_api.bimcc.net/uploads/202301/17/1673943165_C8E5tNmVgm.png',"alt":""}}),_vm._v(_vm._s(item.user_name)+" ")]),_c('p',{staticClass:"time-status"},[_vm._v(" "+_vm._s(_vm.timeFormat(item.endDate, 'yyyy-MM-dd HH:mm'))+" ")])])])])])}),(_vm.list.length === 0)?_c('Empty',{attrs:{"description":"暂无数据","image-size":100}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }